import { useState } from "react";
import { Outlet } from "react-router-dom";

import Navigation from "../screens/HomePage/Navigation";
import footerLogo from "../assets/img/gsc-logo.png";
import TncModal from "../screens/HomePage/TncModal";
import PpModal from "../screens/HomePage/PpModal";

const Layout = () => {
    const [tncShow, setTncShow] = useState(false);
    const [ppShow, setPpShow] = useState(false);

    return (
        <>
            <Navigation />
            <Outlet />
            <footer className="bg-dark color-white py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-2">
                            <img src={footerLogo} className="footer-logo img-fluid" alt="Golden Savings Club Logo" />
                        </div>
                        <div className="col-12 col-md-4 col-lg-7">
                            <ul className="footer-link-ul">
                                <li className="footer-link">
                                    <a href="#termsModal" variant="link" onClick={() => setTncShow(true)}>Terms &amp; Conditions</a>
                                </li>
                                <li className="footer-link">
                                    <a href="#privacyModal" variant="link" onClick={() => setPpShow(true)}>Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 fw-light">
                            <p className="mb-0">Tel: <a href="tel:03301748230">0330 174 8230</a></p>
                            <p className="mb-3">Email: <a href="mailto:hello@goldensavingsclub.com">hello@goldensavingsclub.com</a></p>
                            <small>© Golden Savings Club</small>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12">
                            <p className="small text-center">© The Golden Savings Club. All Rights Reserved. All trademarks are the property of their respective owners.<br />Super Partnerships Worldwide Ltd. Registered office: 124 Finchley Road, London, England, NW3 5JS. Registration number 13293317.</p>
                        </div>
                    </div>
                </div>
            </footer>
            {tncShow && <TncModal show={tncShow} setShow={setTncShow} />}
            {ppShow && <PpModal show={ppShow} setShow={setPpShow} />}
        </>
    );
    
}

export default Layout;