import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Alert, Button, Form, Container, Row, Col, Card } from 'react-bootstrap';

import { useAuth } from '../../providers/AuthContext';
import { POST } from '../../components/Constants';
import Loader from '../../components/Loader';

const Login = () => {
    const auth = useAuth();
    const navigate = useNavigate()
    let location = useLocation();;
    let from = location.state?.from?.pathname || "/rewards";

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const mutation = useMutation((data) => POST("authenticate", auth, data), {
        onSuccess: (rsp) => {
            auth.signIn(rsp.data, () => {
                navigate(from, { replace: true });
            });
        }
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        mutation.mutate({
            Username: email,
            Password: password,
        });
    };

    if (!!auth?.IsAuthenticated()) return <Navigate to="/rewards" />;

    return (
        <Container style={{ paddingTop: 10 + 'rem', paddingBottom: 10 + 'rem' }}>
            <Row className="justify-content-md-center">
                <Col md="8" lg="6">
                    <Card className="p-4">
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label className="fw-bold">Customer Number</Form.Label>
                                <Form.Control type="text" placeholder="Your Customer Number" required autoFocus onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className="fw-bold">Passcode</Form.Label>
                                <Form.Control type="password" placeholder="Password" required autoComplete='current-password' onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>

                            {mutation.error && (
                                <Alert variant="danger small text-center">
                                    {mutation.error?.modelState?.custom[0] ?? mutation.error?.error?.message ?? "Invalid Login. Please try again, or contact customer service for further support."}
                                </Alert>
                            )}
                            {mutation.isLoading && <Loader />}
                            <Button className="btn-custom-primary w-100 mb-4" type="submit" onClick={handleSubmit} disabled={mutation.isLoading || !(email && password)}>
                                Login
                            </Button>
                            <p className="text-center">
                                <Link className="text-dark small" to={"/forgot-password"}>Forgot Your Passcode?</Link>
                            </p>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;