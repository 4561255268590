import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Testimonials from "./Testimonials";
import { useAuth } from "../../providers/AuthContext";
import "./css/home.css";

const HomePage = () => {
    const navigate = useNavigate();
    const { IsAuthenticated } = useAuth();

    const _isAuthenticated = IsAuthenticated();

    return (
        <>
            <Helmet title="Save more with the Golden Savings Club!" />

            <div className="container-fluid" id="home-leading-img" style={{paddingTop: 150 + "px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="jumbotron py-5">
                                <div className="col-12 col-lg-10 offset-lg-2 col-xl-7 offset-xl-5 text-center text-lg-end">
                                    <h1 className="fw-medium color-white pt-3 display-4"><strong>The Golden Lifestyle</strong></h1>
                                    <h4 className="color-white pt-2">Save more with the Golden Savings Club</h4>
                                    <Button variant="light" className="btn-custom-primary btn-lg mt-4 px-5 text-uppercase btn-larger" onClick={() => navigate("/rewards")}>{_isAuthenticated ? "Rewards" : "Login Now"}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid text-center pb-5" id="home-benefits">
                <div className="container">

                    <div className="row pb-5">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <h1 className="color-primary display-5 fw-bold">Exclusive Member Benefits</h1>
                            <p className="text-muted">Just for Golden Savings Club Members</p>
                            <img src="./img/separator.png" alt="Separator"></img>
                        </div>
                    </div>

                    <div className="row">
                        <div className="benefit-card col-12 col-md-6 col-lg-4 mb-4">
                            <img src="img/benefit-01.jpg" className="benefit-image img-fluid" />
                            <span className="overlay-label">Travel</span>
                        </div>
                        <div className="benefit-card col-12 col-md-6 col-lg-4 mb-4">
                            <img src="img/benefit-02.jpg" className="benefit-image img-fluid" />
                            <span className="overlay-label">Days Out</span>
                        </div>
                        <div className="benefit-card col-12 col-md-6 col-lg-4 mb-4">
                            <img src="img/benefit-03.jpg" className="benefit-image img-fluid" />
                            <span className="overlay-label">Dining Out</span>
                        </div>
                        <div className="benefit-card col-12 col-md-6 col-lg-4 mb-4">
                            <img src="img/benefit-04.jpg" className="benefit-image img-fluid" />
                            <span className="overlay-label">Garden Furniture</span>
                        </div>
                        <div className="benefit-card col-12 col-md-6 col-lg-4 mb-4">
                            <img src="img/benefit-05.jpg" className="benefit-image img-fluid" />
                            <span className="overlay-label">Shopping</span>
                        </div>
                        <div className="benefit-card col-12 col-md-6 col-lg-4 mb-4">
                            <img src="img/benefit-06.jpg" className="benefit-image img-fluid" />
                            <span className="overlay-label">Cooking</span>
                        </div>
                    </div>

                    <div className="row py-5">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <h1 className="color-primary display-5 fw-bold">And Much More Inside!</h1>
                            <Button variant="light" className="btn btn-custom-primary btn-lg mt-4 px-5 text-uppercase btn-larger" onClick={() => navigate("/rewards")}>{_isAuthenticated ? "Rewards" : "Login Now"}</Button>
                        </div>
                    </div>


                </div>
            </div>

            <div className="container-fluid" id="testimonial-section">
                <div className="container">
                    <div className="row text-center mb-5">
                        <div className="col-12">
                            <h1 className="display-5 color-white">Here's What Members Love About The Golden Savings Club</h1>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-12">
                            <Testimonials />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid color-white" id="contact-hero">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img src="./img/concierge.jpg" className="img-fluid" alt="The Golden Savings Club Concierge Service" />
                        </div>
                        <div id="contact-info-panel" className="col-12 col-md-6 py-5">
                            <h2 className="display-3 fw-light">The Golden Savings Club Concierge Service</h2>
                            <h3 className="fw-light mt-4">Your Personal Hotline To Get The Most From Your Membership!</h3>
                            <h4 className="fw-bold mt-4">Just £129.99 for the year!</h4>
                            <a className="btn btn-custom-primary btn-lg mt-5 px-5 text-uppercase btn-larger" href="tel:03301748230">Call Now: 0330 174 8230</a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default HomePage;