import React from "react";
import { Carousel } from "react-bootstrap";

const Testimonials = () => {
    return (
        <div class="container-fluid text-white text-center">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 mb-5 mb-lg-0">
                    <div class="row">
                        <div class="col-12 col-lg-5 col-xl-3">
                            <img src="./img/testimonial-lynn.jpg" class="img-fluid rounded-circle mb-5" />
                        </div>
                        <div class="col-12 col-lg-7 col-xl-9 text-start-lg">
                            <p>The Golden Savings Club offers some really nice perks and discounts and I am glad to be a member. Recently, I got a huge discount on a garden furniture set, ready for the summer. Thank you. <strong>Lynn, Nottingham</strong></p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12 col-lg-5 col-xl-3">
                            <img src="./img/testimonial-harold.jpg" class="img-fluid rounded-circle mb-5" />
                        </div>
                        <div class="col-12 col-lg-7 col-xl-9 text-start-lg">
                            <p>I have previously enjoyed being an English Heritage member and was delighted to see that I had a free membership as a member of the Golden Savings Club. What a great members club! <strong>Harold, Bucks.</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Testimonials;